import "./Hamburger.css";

export const Hamburger = () => {
  return (
    <div className="hamburger" id="hamburger">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
