export const defaultNewAddress = {
  name: "",
  address: {
    line1: "",
    line2: "",
    line3: "",
  },
  city: "",
  state: "Maharashtra",
  pincode: "",
};
