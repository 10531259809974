import { v4 as uuid } from "uuid";

/**
 * Product Database can be added here.
 * You can add products of your wish with different attributes
 * */

export const products = [
  {
    title: "Nike",
    description: "Nike Men Black DOWNSHIFTER 11 Road Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205520/2022/6/13/f2bb81b4-769a-43b9-9321-384df1c3e15d1655102465836-Nike-Downshifter-11-Mens-Road-Running-Shoes-4281655102465544-1.jpg",
    price: 3395,
    originalPrice: 3995,
    brand: "Nike",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Grey Zoom Winflo 8 Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205532/2022/5/31/bc63765f-6a2e-40eb-b059-53ad23a8669e1653979824746-Nike-Winflo-8-Mens-Road-Running-Shoes-5151653979824348-1.jpg",
    price: 7050,
    originalPrice: 8295,
    brand: "Nike",
    category: "sports shoes",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "NIKE Men Black RENEW RIDE 3 Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16274074/2022/6/13/91bf8b64-b20d-4b45-83fc-b28c40f7e47f1655106867400-Nike-Renew-Ride-3-Mens-Road-Running-Shoes-7901655106867169-1.jpg",
    price: 5350,
    originalPrice: 6295,
    brand: "Nike",
    category: "sports shoes",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Grey & Black VARSITY COMPETE Training Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/13762670/2021/4/19/4ebdad10-f4c7-4ef2-a30b-6d52b16942541618813125380-Nike-Varsity-Compete-TR-3-Mens-Training-Shoe-565161881312467-1.jpg",
    price: 4396,
    originalPrice: 5495,
    brand: "Nike",
    category: "sports shoes",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Orange Leather JORDAN SERIES ES Basketball Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205522/2022/5/18/5d2d3612-f93b-4c34-8b1c-55e984cbb47a1652861384200-Jordan-Series-ES-Mens-Shoes-8041652861383869-1.jpg",
    price: 5996,
    originalPrice: 7495,
    brand: "Nike",
    category: "sports shoes",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men White ZOOM COURT LITE 3 Leather Tennis Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16274048/2022/3/8/0058f252-eea6-4ca2-a2eb-d9cb485f85ad1646732372724-NikeCourt-Zoom-Lite-3-Mens-Hard-Court-Tennis-Shoes-254164673-1.jpg",
    price: 4271,
    originalPrice: 5695,
    brand: "Nike",
    category: "sports shoes",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description:
      "Nike Men Off-White React Infinity Run Flyknit 3 Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205428/2022/5/31/b1c2c9ab-eb12-4cb3-a27a-58430e61dce91653979815966-Nike-React-Infinity-Run-Flyknit-3-Mens-Road-Running-Shoes-77-1.jpg",
    price: 11895,
    originalPrice: 13995,
    brand: "Nike",
    category: "sports shoes",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men White React Infinity Run Flyknit 3 Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205546/2022/5/31/a546f89b-4176-402b-8dfd-3213c575bd461653979833477-Nike-React-Infinity-Run-Flyknit-3-Mens-Road-Running-Shoes-71-1.jpg",
    price: 11895,
    originalPrice: 13995,
    brand: "Nike",
    category: "sports shoes",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description:
      "Nike Men Grey React Infinity Run Flyknit 3 Road Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205622/2022/5/18/27357e28-b2cb-4d78-b747-010eb02836d81652862297139-Nike-React-Infinity-Run-Flyknit-3-Mens-Road-Running-Shoes-36-1.jpg",
    price: 11196,
    originalPrice: 13995,
    brand: "Nike",
    category: "sports shoes",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "NIKE Men Navy Blue RENEW RIDE 3 Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16046016/2022/6/13/8b331025-aae2-4ec4-9e19-2b475ef828181655106861446-Nike-Renew-Ride-3-Mens-Road-Running-Shoes-9431655106861076-1.jpg",
    price: 5350,
    originalPrice: 6295,
    brand: "Nike",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Blue Legend Essential 2 Training Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17205468/2022/4/13/985e43fb-aabd-4f5b-9e1a-d79f780936671649852746583-Nike-Legend-Essential-2-Mens-Training-Shoes-4311649852746154-1.jpg",
    price: 3596,
    originalPrice: 4495,
    brand: "Nike",
    category: "sports shoes",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Men Black Woven Design Herlz Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/14782114/2021/10/22/4c4de8ce-6d97-4abb-bf15-7ca24ddeb0051634890262921-ADIDAS-Men-Sports-Shoes-1031634890262489-1.jpg",
    price: 1999,
    originalPrice: 3999,
    brand: "Adidas",
    category: "sports shoes",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Men Blue & Red Woven Design Merage M Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17146168/2022/4/11/0d019646-ffca-4045-bf2f-0da3d119a4eb1649661143352-ADIDAS-Men-Blue--Red-Woven-Design-Merage-M-Running-Shoes-378-1.jpg",
    price: 1649,
    originalPrice: 3299,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description:
      "ADIDAS Men Black & White Woven Design Quickflow Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16724964/2022/5/20/61407443-e9f2-4c1b-a9ed-15f2b5ce66bb1653023998571-ADIDAS-Men-Sports-Shoes-4461653023998190-1.jpg",
    price: 3439,
    originalPrice: 4299,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description:
      "ADIDAS Men Black & Grey Woven Design Seize The Street Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17146198/2022/5/20/c7a5395a-cf6e-408c-982f-97f56e588a8a1653024696107-ADIDAS-Men-Black--Grey-Woven-Design-Seize-The-Street-Running-1.jpg",
    price: 3439,
    originalPrice: 4299,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description:
      "ADIDAS Men Charcoal Grey & Green Woven Design Adi Form Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/14782036/2021/11/15/8ae37fbb-68ff-4a68-94e7-7180d76f19551636979110007-ADIDAS-Men-Charcoal-Grey--Green-Woven-Design-Adi-Form-Runnin-1.jpg",
    price: 2639,
    originalPrice: 4799,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description:
      "ADIDAS Men Black & Green Court Rage Perforated Mid-Top Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16724962/2022/5/6/17f47ace-8ae2-4928-98b3-c93fb60bed601651835110371-ADIDAS-Men-Black--Green-Court-Rage-Perforated-Mid-Top-Runnin-1.jpg",
    price: 3449,
    originalPrice: 4599,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description:
      "ADIDAS Men Teal Blue & Grey Woven Design & Striped Jaysaw Reflective Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17146112/2022/6/29/8617e80a-4ca7-41ef-a77c-f3e622fb51d91656495788545-ADIDAS-Men-Teal-Blue--Grey-Woven-Design--Striped-Jaysaw-Refl-1.jpg",
    price: 2364,
    originalPrice: 4299,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Men Navy Blue Woven Design Uniflow Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/14782334/2021/8/9/b8b75889-69ff-4a49-bbbe-dcac43c99a0d1628493151378-ADIDAS-Men-Navy-Blue-Woven-Design-Uniflow-Running-Shoes-9911-1.jpg",
    price: 2474,
    originalPrice: 3299,
    brand: "Adidas",
    category: "sports shoes",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Men Grey Woven Design Flodean Walking Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17146308/2022/5/24/16e3913e-96a6-4939-9a25-53eaa265d9ae1653371833059-ADIDAS-Men-Sports-Shoes-4251653371832751-1.jpg",
    price: 2159,
    originalPrice: 2699,
    brand: "Adidas",
    category: "sports shoes",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men White Woven Design Puma Forza Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17663246/2022/6/7/6aa5815c-4f27-4f50-9349-c9d98d291b361654579120920-Puma-Men-Sports-Shoes-9411654579120638-1.jpg",
    price: 2699,
    originalPrice: 4499,
    brand: "Puma",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Navy Blue Hurdler Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/10124997/2022/5/18/371e56c1-a687-4f8d-b3ee-34cd18ebf9b21652863734579PumaMenNavyBlueHurdlerRunningShoes1.jpg",
    price: 1799,
    originalPrice: 3999,
    brand: "Puma",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Grey City Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17663230/2022/5/26/c32386b2-874b-4f20-99ba-cfb0df1699231653565115683-Puma-Men-Sports-Shoes-6811653565115392-1.jpg",
    price: 2699,
    originalPrice: 4499,
    brand: "Puma",
    category: "sports shoes",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Navy Blue Softride Enzo NXT Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16825024/2022/6/13/b2781717-ce74-450a-863a-042e1faa3fd61655120679156PumaMenNavyBlueSoftrideEnzoNXTRunningShoes1.jpg",
    price: 3299,
    originalPrice: 5499,
    brand: "Puma",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma X RCB Challenger Men White Cricket Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17517614/2022/4/25/1fc1a6b8-ea64-443d-825e-34104aacd8e21650889250560-Puma-Men-Sports-Shoes-7181650889250114-1.jpg",
    price: 3199,
    originalPrice: 3999,
    brand: "Puma",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Flex Essential Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/6743741/2018/6/15/6e7d0a9b-638e-40ce-add3-bd93a8456f391529054157561-Puma-Men-Black-Running-Shoes-4741529054157429-1.jpg",
    price: 2099,
    originalPrice: 2999,
    brand: "Puma",
    category: "sports shoes",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black SOFTRIDE Rift Walking Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/12699240/2022/6/13/2ff06eed-1918-4ac2-9524-ac5f998cd0441655120726546PumaMenBlackSOFTRIDERiftWalkingShoes1.jpg",
    price: 2924,
    originalPrice: 6499,
    brand: "Puma",
    category: "sports shoes",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Mesh Supernal NU 2 Asphalt Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/productimage/2019/7/15/2ea69485-c671-453d-b60b-202f3fdb39101563181757490-1.jpg",
    price: 2099,
    originalPrice: 2999,
    brand: "Puma",
    category: "sports shoes",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Yellow Velocity Nitro 2 Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16824956/2022/6/13/0ecf2f99-fdfb-4c3a-9aac-174fff5103841655114945221PumaMenYellowVelocityNitro2RunningShoes1.jpg",
    price: 8249,
    originalPrice: 10999,
    brand: "Puma",
    category: "sports shoes",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Solid Forza Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17663252/2022/5/27/259658ee-3a90-49df-939c-08d224e430291653635389503-Puma-Men-Sports-Shoes-5831653635389092-1.jpg",
    price: 2699,
    originalPrice: 4499,
    brand: "Puma",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Solid Softride Enzo NXT Running Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16825010/2022/6/13/eba16432-36ab-4547-834c-f727edf517f91655120570050PumaMenBlackSolidSoftrideEnzoNXTRunningSneakers1.jpg",
    price: 4399,
    originalPrice: 5499,
    brand: "Puma",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Men White Solid Woven Design Gel-Contend 4B+ Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16663846/2022/4/28/dec17dcb-9581-4602-805e-bd2b8448b2ce1651139049155-GEL-CONTEND-4B-7691651139048900-1.jpg",
    price: 2959,
    originalPrice: 3699,
    brand: "Asics",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Men Black & White GEL-CONTEND 5B Woven Design Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11735396/2021/9/7/41276fdb-5061-49e1-831f-15289063dce81631009211978-ASICS-Men-Sports-Shoes-2571631009211640-1.jpg",
    price: 3439,
    originalPrice: 4299,
    brand: "Asics",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Men Navy & Silver-Toned GEL-CONTEND 5B Woven Design Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11735416/2021/11/24/6a688f34-f31f-4971-8e61-e4e6cdc60b8d1637735553959-ASICS-Men-Navy--Silver-Toned-GEL-CONTEND-5B-Woven-Design-Run-1.jpg",
    price: 3439,
    originalPrice: 4299,
    brand: "Asics",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Gel-Speed Menace  Men White Synthetic Cricket Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/14063970/2021/11/24/ee04d687-bbf8-467a-831e-8f0fed6488d51637733527775-ASICS-Gel-Speed-Menace--Men-White-Synthetic-Cricket-Shoes-94-1.jpg",
    price: 11049,
    originalPrice: 12999,
    brand: "Asics",
    category: "sports shoes",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Unisex Navy Blue Prime Jogger LS Self-Checked Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15117790/2022/5/18/098519d0-0790-4e6e-8c3e-a5a99f43eec41652864531971-ASICS-Unisex-Navy-Blue-Prime-Jogger-LS-Self-Checked-Running--7.jpg",
    price: 3149,
    originalPrice: 4499,
    brand: "Asics",
    category: "sports shoes",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Men Blue & Black Gel Nimbus 23 Flyte Foam Woven Design Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16663818/2022/1/25/e5ccd8f8-a35d-4600-ba84-982f753ef4811643085036315-GEL-NIMBUS-23-6741643085036015-1.jpg",
    price: 10499,
    originalPrice: 14999,
    brand: "Asics",
    category: "sports shoes",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Men Black & Orange GEL-GLYDE 3 MX Woven Design Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15117816/2021/9/7/ec67d0a3-4d44-48bd-96b7-fada1b86cac61630993148469-GEL-GLYDE-3-MX-8021630993148149-1.jpg",
    price: 7199,
    originalPrice: 8999,
    brand: "Asics",
    category: "sports shoes",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men Green Running Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/2505860/2019/10/19/544a191f-4989-4244-88f8-e385dd3a67a51571484999246-ASICS-Men-Green-Running-Shoes-7711571484998068-1.jpg",
    price: 3199,
    originalPrice: 3999,
    brand: "Asics",
    category: "sports shoes",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description:
      "ASICS Attack Dominate Ff 2 Tokyo  Unisex Red Synthetic Tennis Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/productimage/2021/6/1/0c5538fc-8c72-40cc-a084-9a4b5449db861622560889537-1.jpg",
    price: 6399,
    originalPrice: 7999,
    brand: "Asics",
    category: "sports shoes",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "NIKE Men Blue AIR MAX EXCEE Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16274236/2022/6/13/b9144888-8b87-4f1a-b4c8-c440ee7e5fcb1655106888664-NIKE-AIR-MAX-EXCEE-9031655106888289-1.jpg",
    price: 7305,
    originalPrice: 8595,
    brand: "Nike",
    category: "sneakers",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Black Ebernon Low Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/7487729/2022/5/9/b4ac1ead-a166-477a-8e7e-76ddbc5d658e1652100144093-Nike-Men-Black-Ebernon-Low-Sneakers-4761652100144009-7.jpg",
    price: 3836,
    originalPrice: 4795,
    brand: "Nike",
    category: "sneakers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Grey Solid Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206098/2022/6/21/401ba3a5-1126-486b-a748-ee6d7f5d2b681655813899522-Nike-Men-Grey-Solid-Sneakers-3351655813899205-1.jpg",
    price: 12320,
    originalPrice: 14495,
    brand: "Nike",
    category: "sneakers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men White Solid Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206050/2022/6/21/0ed1a6cd-1b05-4900-b178-6448faff90f61655813881390-Nike-Men-White-Solid-Sneakers-4861655813881097-1.jpg",
    price: 8750,
    originalPrice: 10295,
    brand: "Nike",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Peach-Coloured Air Force 1 PRM NN Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206158/2022/4/18/e4169af0-e950-40d9-93d6-a594879fa1491650268088307-AIR-FORCE-1-07-PRM-NN-6711650268087940-1.jpg",
    price: 10236,
    originalPrice: 12795,
    brand: "Nike",
    category: "sneakers",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Grey Crater Impact SE Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16274226/2022/4/18/21fdc28c-aea5-44ac-8691-d34a665b94ef1650268050267-Nike-Crater-Impact-SE-Mens-Shoes-3951650268049190-1.jpg",
    price: 7756,
    originalPrice: 9695,
    brand: "Nike",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men's Blue Air Presto Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16274248/2022/4/12/b73201b6-455c-4184-aaaf-37c4c6b762c71649746676878-Nike-Air-Presto-Mens-Shoes-5731649746676455-1.jpg",
    price: 9770,
    originalPrice: 11495,
    brand: "Nike",
    category: "sneakers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Yellow Solid Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206154/2022/6/21/eb2e6c2d-38e0-48e7-8aad-7370a009bce41655813906054-Nike-Men-Yellow-Solid-Sneakers-8641655813905657-1.jpg",
    price: 9770,
    originalPrice: 11495,
    brand: "Nike",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Black Court Royale NSW Leather Casual Shoes",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/1110317/2016/1/13/11452663154399-Nike-Men-Black-Leather-Court-Royale-Casual-Shoes-3561452663154038-1.jpg",
    price: 3596,
    originalPrice: 4495,
    brand: "Nike",
    category: "sneakers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description:
      "Nike Men White & Cream-Coloured Colourblocked Air Max Fleece Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/13762810/2021/11/8/4288fec6-06b4-4012-8ff3-356a05b35e7a1636352814357NikeMenWhiteCream-ColouredColourblockedAirMaxFleeceSneakers1.jpg",
    price: 6795,
    originalPrice: 7995,
    brand: "Nike",
    category: "sneakers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Nike",
    description: "Nike Men Air Max Axis Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/6676904/2018/8/31/38d7fa48-069a-47a6-9bbf-4fc7f41d5ab11535711172730-Nike-Air-Max-Axis-1581535711172646-1.jpg",
    price: 7475,
    originalPrice: 8795,
    brand: "Nike",
    category: "sneakers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Originals Men Navy Blue Striped Ozweego Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16558394/2022/5/6/12358092-8daf-4142-b2d1-d0639a5409001651833412076-ADIDAS-Originals-Men-Navy-Blue-Striped-Ozweego-Sneakers-4431-1.jpg",
    price: 9349,
    originalPrice: 10999,
    brand: "Adidas",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description:
      "ADIDAS Originals Men Red & White Woven Design Swift 22 Sustainable Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16558206/2022/5/5/499113ca-9efb-4184-822f-acc58896d5b31651751330994-ADIDAS-Originals-Men-Red--White-Woven-Design-Swift-22-Sneake-1.jpg",
    price: 6459,
    originalPrice: 7599,
    brand: "Adidas",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Originals Men Black Solid Oznova Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18373092/2022/6/22/2c260652-2388-4154-99a7-e06e3c51ae5b1655887220022-ADIDAS-Originals-Men-Casual-Shoes-2941655887219781-1.jpg",
    price: 10799,
    originalPrice: 11999,
    brand: "Adidas",
    category: "sneakers",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Adidas",
    description: "ADIDAS Men Black Woven Design Racer 21 Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17770946/2022/4/28/b7bcbb7a-dde9-4891-8e91-3b97a52e82cd1651121032900-ADIDAS-Men-Sports-Shoes-2201651121032573-1.jpg",
    price: 4799,
    originalPrice: 5999,
    brand: "Adidas",
    category: "sneakers",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men White & Black Colourblocked IDP Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17350726/2022/5/12/1fbba260-8584-4d2a-86fa-a5fa91ddad701652344722392-Puma-Men-White--Black-Colourblocked-IDP-Sneakers-87416523447-1.jpg",
    price: 3599,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Navy Blue and White Colourblocked Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17350728/2022/5/11/0f1ce8f1-6394-4785-9c67-d238acce820c1652267748897-Puma-Men-Casual-Shoes-9391652267748578-1.jpg",
    price: 2699,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Cream-Coloured and Orange Colourblocked Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17350718/2022/5/11/03a74544-12ad-4501-97d5-633b7d9d43971652267731013-Puma-Men-Casual-Shoes-2431652267730668-1.jpg",
    price: 3374,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16309928/2022/6/13/cbaac9b9-04c0-4dbc-9ea0-98e52c0bf5df1655120751920PumaMenBlackSneakers1.jpg",
    price: 1799,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex Green Wild Rider Embroidered Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17776310/2022/4/27/51756731-cb5e-455d-aaf8-9e1a3cf15f151651059310598-Puma-Unisex-Casual-Shoes-3171651059310229-1.jpg",
    price: 7199,
    originalPrice: 8999,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex Off White Mirage Sport Patches Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943258/2022/5/31/a8f5a0e7-fd24-4698-8d31-bb59f9969b771653983311226-Puma-Unisex-Casual-Shoes-8521653983310799-1.jpg",
    price: 7999,
    originalPrice: 9999,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men White & Black Colourblocked IDP Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17350742/2022/5/12/725aaf62-bc16-4a45-8817-a2109b83b05d1652344701904-Puma-Men-White--Black-Colourblocked-IDP-Sneakers-17916523447-1.jpg",
    price: 2699,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description:
      "Puma Unisex White Colourblocked Mirage Sport Patches Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943348/2022/5/31/4b499c52-4cf3-4e05-bd61-55027a42727d1653983334522-Puma-Unisex-Casual-Shoes-4011653983334296-1.jpg",
    price: 7999,
    originalPrice: 9999,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Grey Cblock IDP Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17350714/2022/4/28/19110fd7-1f65-43d7-b709-be868c572fb01651132310532-Puma-Men-Casual-Shoes-1421651132310180-1.jpg",
    price: 3374,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16470850/2021/12/11/c430d9f6-89e0-4d58-92a1-60982a662f0b1639202760038CasualShoes1.jpg",
    price: 1799,
    originalPrice: 3999,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black Cave Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17148202/2022/2/22/b2962f72-50ef-411b-897d-27086439175b1645519140446-Puma-Men-Casual-Shoes-5221645519140065-1.jpg",
    price: 2149,
    originalPrice: 4299,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex White Colourblocked RS-Z Go For Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943394/2022/5/31/fdfdd6bb-2563-486d-823f-c15361b6f1311653983342329-Puma-Unisex-Casual-Shoes-7481653983341835-1.jpg",
    price: 7999,
    originalPrice: 9999,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex Blue and Yellow Colourblocked Leather Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943384/2022/6/21/31534624-27b3-4f0b-a715-20c4f45b94fd1655810735028-Puma-Unisex-Casual-Shoes-4091655810734677-1.jpg",
    price: 6399,
    originalPrice: 7999,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Navy Blue and Off-White Colourblocked Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17350722/2022/5/11/b6eb8c85-2454-456a-bb88-3f43b292e78a1652267741450-Puma-Men-Casual-Shoes-7961652267741125-1.jpg",
    price: 2699,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Grey Orange Dryflex Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11335334/2022/5/4/88e31274-7b17-47e6-accf-46707d7d64e21651661325584PumaMenGreyOrangeDryflexSneakers1.jpg",
    price: 1749,
    originalPrice: 3499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men White Trinity Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11334916/2020/6/12/ef06ee1a-ed06-488d-ab5b-3d090f8913901591960362368PumaMenWhiteTrinityIDPSneakers1.jpg",
    price: 2799,
    originalPrice: 3499,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex Black Solid Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17663242/2022/4/11/3efece4b-454d-4d42-8ab2-0fdf2585daa41649662652585PumaUnisexWhiteEquateSLRunningShoesCasualShoesPumaUnisex1.jpg",
    price: 4199,
    originalPrice: 6999,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex Black TRC Blaze The Triangle Leather Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943386/2022/5/4/141b688e-46f4-4b63-90ae-7c24304f659f1651666955959-Puma-Unisex-Casual-Shoes-4731651666955562-1.jpg",
    price: 3599,
    originalPrice: 4499,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black RS-Z Molded Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943484/2022/6/21/f65150ff-9a3e-45f6-b406-d497fe8f30681655806605618-Puma-Unisex-Casual-Shoes-121655806605241-1.jpg",
    price: 1934,
    originalPrice: 4299,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description:
      "Puma Unisex Navy Blue & Grey Pacer Future Doubleknit Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16943358/2022/3/3/266d361e-fbb6-44fa-b8c8-f33780a93f291646295159849-Puma-Unisex-Casual-Shoes-3521646295159470-1.jpg",
    price: 1799,
    originalPrice: 3999,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Unisex White Colourblocked RS-Fast Limiter Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18423826/2022/5/31/cb7185fb-b679-4951-af08-07e0e913d2151653983371926-Puma-Unisex-Casual-Shoes-2291653983371613-1.jpg",
    price: 1749,
    originalPrice: 3499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black & White Colourblocked Noah Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17543194/2022/3/16/3ad12040-89d4-41eb-8448-d8eddd8c02f71647416365225CasualShoes1.jpg",
    price: 5999,
    originalPrice: 7499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Navy Blue Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17096318/2022/2/10/cb002ba7-8f08-469a-8205-198bf9cd01061644471624999PumaMenNavyBlueAnzarunLiteV2Sneakers1.jpg",
    price: 1924,
    originalPrice: 3499,
    brand: "Puma",
    category: "sneakers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Puma",
    description: "Puma Men Black & White Colourblocked Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17215002/2022/2/18/ee66e550-2e8d-4616-aab2-62b90e3b52321645204508703CasualShoes1.jpg",
    price: 4999,
    originalPrice: 9999,
    brand: "Puma",
    category: "sneakers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Unisex White NURSEWALKER 201 Woven Design Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18257050/2022/5/31/7b5729a3-702d-4bb3-a1eb-d80c22db34b91653982596935-ASICS-Unisex-Casual-Shoes-5871653982596514-1.jpg",
    price: 4799,
    originalPrice: 5999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men White & Blue Perforated Lytecourt Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17957820/2022/5/20/5d679733-5f93-40b2-be85-5f35595176fb1653022265556-LYTECOURT-6001653022265272-1.jpg",
    price: 3999,
    originalPrice: 4999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men White & Blue Perforated Lytecourt Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17957814/2022/5/20/3660a722-3ce3-4228-a650-4dfcb8d47c3c1653030248783-LYTECOURT-6441653030248298-1.jpg",
    price: 3999,
    originalPrice: 4999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men White & Green Perforated Lytecourt Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17957898/2022/5/20/62a6bbf2-fb30-4a98-8695-ca23f55d76f21653022291928-LYTECOURT-8761653022291639-1.jpg",
    price: 3749,
    originalPrice: 4999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Unisex Navy Blue NURSEWALKER 203 Woven Design Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18257048/2022/5/31/95cdba25-5879-4eef-813b-30e6cf5228461653982582369-ASICS-Unisex-Casual-Shoes-3551653982582072-1.jpg",
    price: 4799,
    originalPrice: 5999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Unisex White NURSEWALKER 203 Woven Design Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18257046/2022/5/31/01962f78-dc1a-4c1f-9874-087aec1b55a21653982570684-ASICS-Unisex-Casual-Shoes-5111653982570399-1.jpg",
    price: 4799,
    originalPrice: 5999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Unisex White NURSEWALKER 100 Woven Design Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18257052/2022/5/31/97cead94-5e73-4550-ab1f-912c83ca96eb1653982605503-ASICS-Unisex-Casual-Shoes-1481653982605268-1.jpg",
    price: 3199,
    originalPrice: 3999,
    brand: "Asics",
    category: "sneakers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men Black Woven Design STORMER LS Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/13250790/2022/4/25/9777c067-7c5c-4ba7-abfe-b19d0417636d1650883554778ASICSMenBlackWovenDesignSneakers1.jpg",
    price: 2399,
    originalPrice: 3999,
    brand: "Asics",
    category: "sneakers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Unisex White NURSEWALKER 202 Perforations Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18257044/2022/5/31/8bf02761-857f-48fc-b859-4088e61edf2a1653982558324-ASICS-Unisex-Casual-Shoes-4121653982558024-1.jpg",
    price: 4799,
    originalPrice: 5999,
    brand: "Asics",
    category: "sneakers",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men Black & Charcoal TORRANCE B Sneakers",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15117814/2022/4/25/dc9f88d3-3377-4b60-856b-78d154d8069e1650883647464ASICSMenBlackCharcoalTORRANCEBSneakers1.jpg",
    price: 2924,
    originalPrice: 4499,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },
  {
    title: "Asics",
    description: "ASICS Men Blue Colourblocked PU Sneakers TARTHER BLAST",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15053670/2021/8/9/033dd115-198c-4bcd-b901-e9db0010a3b31628508535555ASICSMenBlueColourblockedPUSneakers1.jpg",
    price: 6299,
    originalPrice: 8999,
    brand: "Asics",
    category: "sneakers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
    _id: uuid(),
  },

  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Beige & Blue Comfort Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206680/2022/4/18/5a28ac04-a102-4f5e-9c7a-00bbf134cb181650269320920-Nike-Oneonta-Sandals-2081650269320506-1.jpg",
    price: 4245,
    originalPrice: 4995,
    brand: "Nike",
    category: "sandals",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Black VISTA Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206642/2022/6/10/7b30b59c-fbd9-4227-8056-d6b8c92c95b01654863602225-Nike-Vista-Mens-Sandals-9361654863601975-1.jpg",
    price: 3565,
    originalPrice: 4195,
    brand: "Nike",
    category: "sandals",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "NIKE Men Grey & Black Oneonta Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206646/2022/6/10/96e2da8f-ab55-4081-8914-8a6a8056a0bb1654863611190-Nike-Oneonta-Sandals-2561654863610749-1.jpg",
    price: 4245,
    originalPrice: 4995,
    brand: "Nike",
    category: "sandals",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Brown Oneonta Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206666/2022/6/10/6509c0d2-f474-4cb2-bcc8-f5411b65a5841654863632322-Nike-Oneonta-Sandals-6881654863631919-1.jpg",
    price: 4245,
    originalPrice: 4995,
    brand: "Nike",
    category: "sandals",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Olive Green Vista Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206654/2022/6/10/bea94d40-d147-421f-b7c3-13f1777408af1654863617033-Nike-Vista-Mens-Sandals-1281654863616617-1.jpg",
    price: 3565,
    originalPrice: 4195,
    brand: "Nike",
    category: "sandals",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },

  {
    _id: uuid(),
    title: "Adidas",
    description: "ADIDAS Men Black Hengat Solid Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/14781448/2021/9/14/940e38ea-0251-454a-9336-9af343043ba21631612967235-ADIDAS-Men-Black-Hengat-Solid-Sports-Sandals-469163161296639-1.jpg",
    price: 1299,
    originalPrice: 1999,
    brand: "Adidas",
    category: "sandals",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Adidas",
    description: "ADIDAS Men Brown Solid Planton Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16558038/2022/4/28/da80c3a9-bec8-47e0-8d31-99cfe54c98a71651124307349-ADIDAS-Men-Sandals-2851651124307103-1.jpg",
    price: 1649,
    originalPrice: 2999,
    brand: "Adidas",
    category: "sandals",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },

  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Grey & Black Relay MU Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/7252378/2021/5/26/d182f3fe-20bb-4fb8-a77a-6d7c49ea998f1622023374042RelayMU1.jpg",
    price: 1264,
    originalPrice: 2299,
    brand: "Puma",
    category: "sandals",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Comfort Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15503358/2021/9/16/33294184-f0fa-4e7e-98c5-06153354b29e1631802256770Sandals1.jpg",
    price: 1799,
    originalPrice: 2999,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Street walk Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/12285860/2020/11/9/20f5f0f8-829c-4c2e-9eed-b5ea7224d3861604903207272-Puma-Men-Sports-Sandals-7521604903205799-1.jpg",
    price: 1499,
    originalPrice: 2499,
    brand: "Puma",
    category: "sandals",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Grey Ultimate Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/12951194/2020/12/3/c46c5f79-59e9-42a4-9240-c2b9fea2275a1606994240109-Puma-Men-Sports-Sandals-8001606994238504-1.jpg",
    price: 1839,
    originalPrice: 2299,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Comfort Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16470856/2021/12/12/0ce5e34d-bcb8-4dd9-bd2e-6101dfaa42271639303045635Sandals1.jpg",
    price: 1874,
    originalPrice: 2499,
    brand: "Puma",
    category: "sandals",
    inStock: false,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Shire Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/productimage/2019/8/27/4355bd01-de8f-4f01-afb8-4360de57ae331566895900519-1.jpg",
    price: 1599,
    originalPrice: 1999,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black & Grey Comfort Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15503192/2021/9/16/da2fbd8e-44ba-49fc-b208-1baca90866111631797812621Sandals1.jpg",
    price: 1679,
    originalPrice: 2799,
    brand: "Puma",
    category: "sandals",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Grey Street Walk Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/12285856/2020/12/3/8885a6a5-adfc-4e36-a99f-b745d33cb2081606994228202-Puma-Men-Sports-Sandals-3781606994226645-1.jpg",
    price: 1499,
    originalPrice: 2499,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Grey Outstretch V2 Sports Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17002580/2022/2/22/933313e4-0d20-408e-8a4e-056a65f255f91645513412438-Puma-Men-Flip-Flops-951645513412077-1.jpg",
    price: 1819,
    originalPrice: 2799,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Unisex Off-White RS Sports Sandal",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17351002/2022/4/11/a339863e-9d4e-492d-82b2-bd4c8096e4ff1649657194254-Puma-Unisex-Sandals-1721649657193867-1.jpg",
    price: 4799,
    originalPrice: 5999,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Stride one8 Comfort Sandals",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15312388/2022/6/13/054ce878-d7e8-4326-848f-1ec40e10c3b51655120843349PumaMenBlackStrideone8ComfortSandals1.jpg",
    price: 2099,
    originalPrice: 2999,
    brand: "Puma",
    category: "sandals",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },

  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Black KAWA Printed Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/2314924/2017/12/17/11513492907870-Nike-Men-Flip-Flops-5641513492907742-1.jpg",
    price: 2236,
    originalPrice: 2795,
    brand: "Nike",
    category: "slippers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Beige ASUNA 2 Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206662/2022/6/10/55b37d7d-7f6a-405b-a56d-86bfaeee33b81654863625530-Nike-Asuna-2-Mens-Slides-1471654863625225-1.jpg",
    price: 2545,
    originalPrice: 2995,
    brand: "Nike",
    category: "slippers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Red & Black Solid Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206684/2022/5/30/aa06a929-e4ec-4d73-87fc-2e8f6c68ae221653910421227-Jordan-Super-Play-Mens-Slides-761653910420674-1.jpg",
    price: 3565,
    originalPrice: 4195,
    brand: "Nike",
    category: "slippers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Blue Printed Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206656/2022/4/18/2e790304-2af7-47f7-84c7-6ba140a4f17e1650269283534-Nike-Victori-One-Mens-Printed-Slides-5841650269283223-1.jpg",
    price: 2076,
    originalPrice: 2595,
    brand: "Nike",
    category: "slippers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Orange OnDeck Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16273688/2022/2/22/2c68f688-754b-4b2f-bd9d-8159df838c331645519582835-Nike-On-Deck-Mens-Flip-Flop-8841645519582458-1.jpg",
    price: 1356,
    originalPrice: 1695,
    brand: "Nike",
    category: "slippers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men White & Black KAWA SLIDE Slip-On Flip Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/2287077/2017/12/14/11513254262973-Nike-Men-Flip-Flops-8631513254262870-1.jpg",
    price: 2236,
    originalPrice: 2795,
    brand: "Nike",
    category: "slippers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Black JORDAN BREAK SLIDE Slip-On",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11045800/2022/6/7/c5608c60-8295-4c47-a97f-4dbf71e5e9ad1654597016878-Nike-Men-JORDAN-BREAK-SLIDE-Thong-Flip-Flops-127165459701651-1.jpg",
    price: 1695,
    originalPrice: 1995,
    brand: "Nike",
    category: "slippers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Black Textured Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/10714312/2019/11/14/e45df58b-74cf-453d-bb2e-ed0215c7cb511573732405638-Nike-Offcourt-Mens-Slide-8581573732405102-1.jpg",
    price: 1996,
    originalPrice: 2495,
    brand: "Nike",
    category: "slippers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men White OFFCOURT Printed Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/10714296/2019/10/14/f01bee9f-a11a-4345-8cee-d50dbf19235e1571047970319-Nike-Men-White-OFFCOURT-Printed-Sliders-1421571047969338-1.jpg",
    price: 1996,
    originalPrice: 2495,
    brand: "Nike",
    category: "slippers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Nike",
    description: "Nike Men Black Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17206690/2022/4/18/c0626c04-a92f-406b-945c-9ef4ef8cad4f1650276444580-Nike-Offcourt-Mens-Slides-1501650276444197-1.jpg",
    price: 1996,
    originalPrice: 2495,
    brand: "Nike",
    category: "slippers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },

  {
    _id: uuid(),
    title: "Adidas",
    description: "ADIDAS Unisex Navy & White Adilette Aqua Striped Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/8615789/2019/3/19/978103ba-ba3b-4f68-a6b4-1c8ec4324bf51552991853971-ADIDAS-Unisex-Navy-Blue-Striped-Sliders-3541552991853352-1.jpg",
    price: 1839,
    originalPrice: 2299,
    brand: "Adidas",
    category: "slippers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Adidas",
    description:
      "ADIDAS Originals Men Navy Blue & White Striped DURAMO Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/13442050/2021/6/11/9edebba5-99ca-4e6c-8e94-d771d1b6b9701623401822695-ADIDAS-Men-Flip-Flops-2461623401822278-1.jpg",
    price: 3999,
    originalPrice: 4999,
    brand: "Adidas",
    category: "slippers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Adidas",
    description:
      "ADIDAS Men Navy Blue & White Brand Logo Printed Eezay Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/12031600/2020/11/18/279c9ae8-296c-46ea-a917-0bc99d2306c51605692650576-ADIDAS-Men-Flip-Flops-5251605692649231-1.jpg",
    price: 1499,
    originalPrice: 1999,
    brand: "Adidas",
    category: "slippers",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Adidas",
    description:
      "ADIDAS Men Black & White Brand Logo Printed Eezay Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/12709818/2020/11/18/cd8bc0e4-48c3-4e9b-a7ae-22f1728c9e5e1605700819201-ADIDAS-Men-Flip-Flops-2341605700818045-1.jpg",
    price: 1599,
    originalPrice: 1999,
    brand: "Adidas",
    category: "slippers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Adidas",
    description: "ADIDAS Men Grey Camouflage Printed Distincto Slip-On",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16558006/2022/3/9/436d9d31-1afc-44e3-97d7-4629c7db513a1646801444361-ADIDAS-Men-Flip-Flops-4371646801443858-1.jpg",
    price: 1299,
    originalPrice: 1999,
    brand: "Adidas",
    category: "slippers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Adidas",
    description:
      "ADIDAS Men Brown Brand Logo Textured Aviate M Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16557956/2022/3/23/f8f732d0-884d-4285-aa90-0bb2b24da7f01648031594983-ADIDAS-Men-Brown-Brand-Logo-Textured-Thong-Flip-Flops-743164-1.jpg",
    price: 974,
    originalPrice: 1299,
    brand: "Adidas",
    category: "slippers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },

  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Unisex Grey Flip Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18424314/2022/5/25/34b1e7f6-d36d-43dd-b8f8-e25bb0c05a361653461824228PumaUnisexGreyFlipFlops1.jpg",
    price: 509,
    originalPrice: 599,
    brand: "Puma",
    category: "slippers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Unisex Grey Solid Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/11334740/2020/6/17/99df1309-16d7-4ea1-885a-eed14f847d7e1592396527494AllenSollySportFlipFlopsPumaUnisexSportsSandalsPumaMenSports1.jpg",
    price: 1079,
    originalPrice: 1799,
    brand: "Puma",
    category: "slippers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "PUMA Unisex Black Purecat Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/1705699/2016/12/28/11482907837545-Puma-Unisex-Flip-Flops-2221482907837356-1.jpg",
    price: 849,
    originalPrice: 1699,
    brand: "Puma",
    category: "slippers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Blue Printed Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18197934/2022/5/9/c39f80ac-4a93-4956-ad17-adc25e140fa01652082473873CasualShoes1.jpg",
    price: 1039,
    originalPrice: 1599,
    brand: "Puma",
    category: "slippers",
    inStock: true,
    rating: 1,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Keru Flip Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15312432/2021/8/30/4c300671-1998-4a67-8e1d-90e804d633a71630347963909FlipFlops1.jpg",
    price: 974,
    originalPrice: 1499,
    brand: "Puma",
    category: "slippers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15503392/2021/9/16/fa4ac98a-a2b0-4628-bec1-7eb5327ab5861631797830085FlipFlops1.jpg",
    price: 1049,
    originalPrice: 1499,
    brand: "Puma",
    category: "slippers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Unisex Blue Flip Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18424318/2022/5/25/805e8edb-bd29-43c3-af85-142d25c4c2131653461523150PumaUnisexBlueFlipFlops1.jpg",
    price: 509,
    originalPrice: 599,
    brand: "Puma",
    category: "slippers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Blue Coasta V2 Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/18096132/2022/5/16/8e6baeab-ceeb-46aa-9cfe-228630c8b60d1652684064204-Puma-Men-Flip-Flops-7651652684063745-1.jpg",
    price: 639,
    originalPrice: 799,
    brand: "Puma",
    category: "slippers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Blue & Black Printed Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17215036/2022/2/19/d485eee4-8e67-48b7-8a74-a960c0cc68821645263114484FlipFlops1.jpg",
    price: 549,
    originalPrice: 999,
    brand: "Puma",
    category: "slippers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Puma",
    description: "Puma Men Black & Yellow Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17214978/2022/2/19/ea6d34bf-4bad-4d2c-adf8-e6ffcd1e881e1645260443796FlipFlops1.jpg",
    price: 1039,
    originalPrice: 1599,
    brand: "Puma",
    category: "slippers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },

  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS As001  Unisex Black  White Printed Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/productimage/2021/6/2/84f9de41-83e6-433a-8ff5-484c709fec451622624198470-1.jpg",
    price: 1039,
    originalPrice: 1299,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS Sprl Slide  Unisex Black  White Printed Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/productimage/2021/5/26/9076605e-2b72-47b7-b9af-ae15f0ca0c211622025632750-1.jpg",
    price: 1759,
    originalPrice: 2199,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS Unisex Red & Black Brand Logo Embossed Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17471696/2022/4/29/7b4e13ba-43c7-4c4e-b476-fb57cf3d935f1651213400126-SPRL-SLIDE-5911651213399807-1.jpg",
    price: 1649,
    originalPrice: 2199,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS Unisex Yellow & Black Typography Sliders AS003",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17215834/2022/2/19/67212201-5912-4906-8d94-8f4a67502f1c1645260418106ASICSUnisexYellowFlipFlops1.jpg",
    price: 1359,
    originalPrice: 1599,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description:
      "ASICS Unisex Teal Blue & White Brand Logo Print Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16653246/2022/1/25/8bfcac36-9832-4a15-9d54-f56599c9f56f1643090622842-SPRL-SLIDE-5871643090622499-1.jpg",
    price: 1234,
    originalPrice: 1899,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS Adult White & Blue AS003 Printed Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15825766/2021/10/16/5dbdede8-cd6b-4807-9bcb-40db3b400da91634378760208ASICSUnisexWhite1.jpg",
    price: 1599,
    originalPrice: 1999,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description:
      "ASICS Unisex Blue & Yellow Printed ZORIAN BM Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17215832/2022/2/19/436fe4b9-66e2-4c56-b474-40885ca917df1645267241936ASICSUnisexBlueFlipFlops1.jpg",
    price: 1614,
    originalPrice: 1899,
    brand: "Asics",
    category: "slippers",
    inStock: true,
    rating: 4,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS Unisex Black & Blue Zorian BM Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17215830/2022/2/19/5f1545b0-24a0-4464-9401-dfc38f0c84621645264815081ASICSUnisexBlackFlipFlops1.jpg",
    price: 1614,
    originalPrice: 1899,
    brand: "Asics",
    category: "slippers",
    inStock: true,
    rating: 2,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description:
      "ASICS Unisex Mint Green & White ZORIAN BM Brand Logo Print Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/15117942/2021/11/29/729e05c6-ae20-435d-8df1-4db614d7347c1638182814704-ZORIAN-BM-9181638182814408-1.jpg",
    price: 1139,
    originalPrice: 1899,
    brand: "Asics",
    category: "slippers",
    inStock: true,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description:
      "ASICS Unisex Blue & Bronze-Toned Printed Zorian BM Thong Flip-Flops",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/17215836/2022/2/19/9b5cbe83-e9bc-459f-a8d0-ee44a8a312181645267098558ASICSUnisexBlueFlipFlops1.jpg",
    price: 1614,
    originalPrice: 1899,
    brand: "Asics",
    category: "slippers",
    inStock: true,
    rating: 3,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
  {
    _id: uuid(),
    title: "Asics",
    description: "ASICS Unisex Red SPRL SLIDE Sliders",
    productImage:
      "https://assets.myntassets.com/dpr_2,q_60,w_210,c_limit,fl_progressive/assets/images/16338250/2021/12/2/982444e1-1530-4496-ae07-4d47a2136bf81638442913124ASICSUnisexRed1.jpg",
    price: 1869,
    originalPrice: 2199,
    brand: "Asics",
    category: "slippers",
    inStock: false,
    rating: 5,
    qtyOrdered: 0,
    deliveryCharge: 0,
  },
];
